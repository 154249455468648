let capabilities_flag = false;
let timer_id;
let pan = 0;
let tilt = 0;
let PAN_SCALE_MAX, PAN_SCALE_MIN, TILT_SCALE_MAX, TILT_SCALE_MIN, ZOOM_SCALE_MAX, ZOOM_SCALE_MIN;
let PAN_AMOUNT, TILT_AMOUNT;
let camera_init = false;
let ptzLevel;
let ACTIONS = {
    START: 'START',
    UP_START: 'TILT',
    DOWN_START: 'TILT',
    LEFT_START: 'PAN',
    RIGHT_START: 'PAN',
    ZOOM: 'ZOOM',
    RESET: 'RESET',
    UP_END: 'END',
    DOWN_END: 'END',
    LEFT_END: 'END',
    RIGHT_END: 'END'
}

export function PTZCommandListener(videoTrack, txt) {
    let control = getCmd(txt);
    switch (control) {
        case 'RESET':
            cameraInit(videoTrack);
            return;
        case 'zoom':
            setCameraControl(videoTrack, txt);
            return;
        case 'END':
            clearInterval(timer_id);
            return;
        default:
            timer_id = setInterval((setCameraControl), 100, videoTrack, txt);
            return;
    }
}

function getCmd(control) {
    let cmd;
    if (control === 'START') {
        cmd = 'START'
    } else {
        cmd = JSON.parse(control).key;
    }
    switch (ACTIONS[cmd]) {
        case 'START':
            return "RESET";
        case 'TILT':
            return "tilt";
        case 'PAN':
            return "pan";
        case 'ZOOM':
            return "zoom";
        case 'RESET':
            camera_init = false;
            return 'RESET';
        case 'END':
            return 'END';
        default:
            console.log("unknown control : ", control);
            return 'END';
    }
}

function getValue(control) {
    let cmd;
    if (control === 'START') {
        cmd = 'START'
    } else {
        cmd = JSON.parse(control).key;
    }
    switch (cmd) {
        case 'DOWN_START':
            if (tilt + TILT_AMOUNT < TILT_SCALE_MAX) {
                tilt = tilt + TILT_AMOUNT;
                return tilt;
            } else {
                return tilt;
            }

        case 'UP_START':
            if (tilt - TILT_AMOUNT > TILT_SCALE_MIN) {
                tilt = tilt - TILT_AMOUNT;
                return tilt;
            } else {
                return tilt;
            }
        case 'LEFT_START':
            if (pan - PAN_AMOUNT > PAN_SCALE_MIN) {
                pan = pan - PAN_AMOUNT;
                return pan;
            } else {
                return pan;
            }
        case 'RIGHT_START':
            if (pan + PAN_AMOUNT < PAN_SCALE_MAX) {
                pan = pan + PAN_AMOUNT;
                return pan;
            } else {
                return pan;
            }
        case 'ZOOM':
            ptzLevel = ZOOM_SCALE_MIN + ((ZOOM_SCALE_MAX - ZOOM_SCALE_MIN) / 100) * JSON.parse(control).ptzLevel;
            return ptzLevel;
        case 'RESET':
            return 0;
        default:
            console.log("unknown control : ", control);
            return 0;
    }
}

function cameraInit(videoTrack) {
    if (!camera_init) {
        pan = 0;
        tilt = 0;
        videoTrack.applyConstraints({"advanced":[{"pan":pan, "tilt":tilt, "zoom":ZOOM_SCALE_MIN}]});
        camera_init = true;
    }
}

function setCameraControl(videoTrack, txt) {
    let control = getCmd(txt);
    let value = getValue(txt);
    let param = JSON.parse(`{"advanced": [{"${control}": ${parseInt(value)}}]}`);
    videoTrack.applyConstraints(param);
}

export function getCameraScale(capabilities) {
    if (!capabilities_flag) {
        PAN_SCALE_MAX = capabilities.pan.max;
        PAN_SCALE_MIN = capabilities.pan.min;
        PAN_AMOUNT = (PAN_SCALE_MAX - PAN_SCALE_MIN) / 100;

        TILT_SCALE_MAX = capabilities.tilt.max;
        TILT_SCALE_MIN = capabilities.tilt.min;
        TILT_AMOUNT = (TILT_SCALE_MAX - TILT_SCALE_MIN) / 100;

        ZOOM_SCALE_MAX = capabilities.zoom.max;
        ZOOM_SCALE_MIN = capabilities.zoom.min;
        capabilities_flag = true;
    }
}

